import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { ModalContainer, ProcessingView } from '@vw-marketing/us-components';

import { AppConstants } from '../../utils/app-constants';
import { disableScroll, unsetPagemainZindex } from '../../utils/general';
import { FeatureAppMode } from '../..';
import { FormState, ModelImage } from '../../typings/general';
import { onHandleLoadTracking } from '../../utils/tracking-helpers';
import { StyledModalContainer } from './styled';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { useFeatureAppMainConfig } from '../../services/use-feature-app-main-config';
import { useModelData } from '../../services/use-model-data';
import { useTrackingManager } from '../../services/use-tracking-manager';
import Banner from '../../components/banner';
import Form from '../../components/form';
import FormikCustom from '../../components/formik-custom';
import ModalCTA from '../../components/modal-cta';

interface ModalFormModeProps {
  mode?: FeatureAppMode;
}
const ModalFormMode: React.FC<ModalFormModeProps> = ({ mode }) => {
  const [active, setActive] = useState<boolean>(false);
  const [internalActive, setInternalActive] = useState(active);
  const { faHostServerUrl = '' } = useFeatureAppConfig();

  useEffect(() => {
    // Avoid the navigation menu overlap issue
    unsetPagemainZindex();
  }, []);

  // Tracking
  const {
    formConfiguration,
    fieldsConfiguration,
    inputFields,
  } = useFeatureAppMainConfig();
  const trackingManager = useTrackingManager();

  useEffect(() => {
    disableScroll(active);
    setInternalActive(active);
    return () => {
      disableScroll(false);
    };
  }, [active]);

  const [formState, setFormState] = React.useState<FormState>('filling');
  const modalData = useModelData();
  const [modelImage, setModelImage] = React.useState<ModelImage>(
    modalData?.modelImage || {
      url: faHostServerUrl + AppConstants.Ngw6Logo480x216,
      alt: 'Error image',
    },
  );

  const onCloseHandle = () => {
    setActive(false);
    onHandleLoadTracking(
      'close',
      trackingManager,
      formConfiguration,
      modalData,
    );
  };

  const onOpenHandle = () => {
    setActive(true);
    onHandleLoadTracking('load', trackingManager, formConfiguration, modalData);
  };

  return (
    <div id="formModal">
      {mode === 'button' ? (
        <ModalCTA
          onClickHandle={onOpenHandle}
          label={formConfiguration.CTAlabel}
        />
      ) : (
        <Banner showModal={onOpenHandle} />
      )}
      <StyledModalContainer>
        {formState === 'submitting' && <ProcessingView active={true} />}
        {active && (
          <FormikCustom
            setFormState={setFormState}
            setModelImage={setModelImage}
          >
            <ModalContainer
              active={internalActive}
              onCloseHandler={onCloseHandle}
            >
              <Form
                formState={formState}
                modelImage={modelImage}
                setFormState={setFormState}
                onCloseHandle={onCloseHandle}
                withPadding={true}
                fieldsConfiguration={fieldsConfiguration}
                formConfiguration={formConfiguration}
                fieldsFormat={inputFields}
              />
            </ModalContainer>
          </FormikCustom>
        )}
      </StyledModalContainer>
    </div>
  );
};

export default ModalFormMode;
