import {
  TextAppearance,
  TextAlignment,
  Button,
  ButtonAppearance,
  Richtext,
  TextCustom,
  TextLineHeight,
  TextWeight,
  TextSize,
} from '@vw-marketing/us-components';
import React from 'react';
import { ContentWrapper, StateWrapper } from '../styled';
import { DEFAULT_FAILED_CONTENT_CONFIG, descriptionTextStyle } from './helpers';
import { FailedTextWrapper, HeaderWrapper } from './styled';

export interface FailedConfiguration {
  headerLabel?: string;
  description?: string;
  buttonCtaLabel?: string;
}
interface FailedViewProps {
  formName: string;
  setFormStateHandler: () => void;
  inline?: boolean;
  contentConfig?: FailedConfiguration;
}
export const FailedView = ({
  formName,
  setFormStateHandler,
  inline,
  contentConfig,
}: FailedViewProps) => {
  const failedContent = { ...DEFAULT_FAILED_CONTENT_CONFIG, ...contentConfig };
  const buttonCtaLabel = failedContent?.buttonCtaLabel?.replace(
    '$$formName$$',
    formName,
  );
  return (
    <StateWrapper inline={inline}>
      <ContentWrapper inline={inline}>
        <HeaderWrapper>
          <TextCustom
            appearance={TextAppearance.headline300}
            style={{
              fontSize: TextSize.TextSize28,
              lineHeight: TextLineHeight.TextLineHeight32,
              fontWeight: TextWeight.bold,
              textAlign: TextAlignment.center,
            }}
          >
            {failedContent.headerLabel}
          </TextCustom>
        </HeaderWrapper>
        <FailedTextWrapper>
          <Richtext
            markdown={failedContent?.description || ''}
            overrides={descriptionTextStyle}
            dangerouslyParseRawHTML
          />
        </FailedTextWrapper>
        <Button
          appearance={ButtonAppearance.Primary}
          onClick={setFormStateHandler}
        >
          {buttonCtaLabel}
        </Button>
      </ContentWrapper>
    </StateWrapper>
  );
};
