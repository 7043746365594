import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';
import { HistoryServiceV1 } from '@feature-hub/history-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { ThemeProvider } from '@vw-marketing/us-components';
import { DisclaimerManagerV1 } from '@volkswagen-onehub/disclaimer-manager';
import { FeatureappServiceV1 } from '@volkswagen-onehub/featureapp-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';
import { TrackingManagerV1 } from '@volkswagen-onehub/tracking-service';
import { ZipManagerV1, defineZipManager } from '@volkswagen-onehub/zip-manager';
import { FaContentServiceV1 } from '@volkswagen-onehub/fa-content-service';

import React from 'react';
import { App } from './app';

import { FeatureAppEnvironmentContext } from './services/use-feature-app-environment';
import { HistoryContext } from './services/use-history';

import {
  FeatureAppMemoryStoreV1,
  featureAppMemoryStoreDefinition,
} from './services/feature-app-memory-store';

import { StoreModel } from './hooks-store/typings/store';
import {
  FormConfiguration,
  FieldsConfiguration,
} from './typings/configuration';

export interface FeatureAppDependencies extends FeatureServices {
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:history': HistoryServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 's2:server-request'?: ServerRequestV1;
  readonly 'disclaimer-manager': DisclaimerManagerV1;
  readonly 'featureapp-service': FeatureappServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'service-config-provider': ServiceConfigProviderV1;
  readonly 'tracking'?: TrackingManagerV1;
  readonly 'feature-app-memory-store': FeatureAppMemoryStoreV1<StoreModel>;
  readonly 'zip-manager': ZipManagerV1;
  readonly 'fa-content-service'?: FaContentServiceV1;
}

export type FeatureAppMode =
  | 'inline'
  | 'default'
  | 'modal'
  | 'button'
  | 'inline-image';

export interface FeatureAppConfig {
  readonly mode?: FeatureAppMode;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly footerDisclaimersFeatureAppUrl?: string;
  readonly scrollTopOffset?: number;
  readonly configureFeatureAppId?: string;
  readonly faServicesUrl: string;
  readonly formConfiguration: FormConfiguration;
  readonly fieldsConfiguration: FieldsConfiguration;
  readonly carlineId: string;
  readonly hasContentConfig?: boolean;
  readonly faHostServerUrl: string;
}

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:history': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'disclaimer-manager': '^1.1.0',
      'feature-app-memory-store': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'locale-service': '^1.0.0',
      'service-config-provider': '^1.0.0',
      'zip-manager': '^1.2.1',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'tracking': '^1.0.0',
      'fa-content-service': '^1.0.0',
    },
  },
  ownFeatureServiceDefinitions: [
    featureAppMemoryStoreDefinition,
    defineZipManager({ countryCode: 'US' }),
  ],

  create: env => {
    const { featureServices } = env;

    const {
      's2:async-ssr-manager': asyncSsrManager,
      's2:history': historyService,
      's2:serialized-state-manager': serializedStateManager,
      'feature-app-memory-store': memoryStore,
    } = featureServices;

    const history = asyncSsrManager
      ? historyService.createStaticHistory()
      : historyService.createBrowserHistory();

    const serializedState = serializedStateManager.getSerializedState();

    if (serializedState && !memoryStore.getState()) {
      memoryStore.setState(JSON.parse(serializedState));
    }

    serializedStateManager.register(() =>
      JSON.stringify(memoryStore.getState()),
    );

    return {
      render: () => (
        <HistoryContext.Provider value={history}>
          <FeatureAppEnvironmentContext.Provider value={env}>
            <ThemeProvider theme="main">
              <App />
            </ThemeProvider>
          </FeatureAppEnvironmentContext.Provider>
        </HistoryContext.Provider>
      ),
    };
  },
};

export default featureAppDefinition;
