import {
  createTagOverride,
  TextAlignment,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
} from '@vw-marketing/us-components';

export const DEFAULT_FAILED_CONTENT_CONFIG = {
  headerLabel: 'Oops! Something went wrong!',
  description:
    'We couldn’t receive your message at the moment. Please try again.',
  buttonCtaLabel: 'Back to $$formName$$',
};

const descriptionTextTag = createTagOverride(TextCustom, {
  appearance: TextAppearance.headline200,
  style: {
    textAlign: TextAlignment.center,
    fontSize: TextSize.TextSize20,
    lineHeight: TextLineHeight.TextLineHeight24,
  },
  tag: TextTag.p,
});

export const descriptionTextStyle: any = {
  div: descriptionTextTag,
  p: descriptionTextTag,
  span: descriptionTextTag,
};
