import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledInlineImageFormContainer = styled.div<any>`
  ${({ theme }) => `
  width: 100%;
  background: white;
  padding: 30px 0 50px 0;
  grid-area: form;

  .form-title {
    padding: 0;
    margin: 0;
  }
  .form-fields {
    label {
      font-weight: bold;
    }
  }
  .form-submit-button {
    width: ${theme.size.grid015};
  }

  .form-legal-disclaimer {
    padding-right: 0;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 7px 44px 19px 43px;
    .form-submit-button {
      width: ${theme.size.grid004};
    }
    .form-title {
      margin-top: 57px;
    }
  }

  @media (min-width: ${Breakpoints.b2560}px) {
    padding: 80px;
  }
`}
`;

export const StyledInlineImageWrapper = styled.div<any>`
  ${({ theme }) =>
    `
    display: grid;
    width: 100%;
    max-width: ${Breakpoints.b2560}px;
    padding: 58px 30px 30px 30px;
    grid-template-columns: 100%;
    grid-template-areas: 
      "title"
      "form"
      "image";
    @media (min-width: ${Breakpoints.b1280}px) {
      padding: 81px ${theme.size.grid002};
      grid-template-columns: 50% 50%;
      grid-template-areas: 
        "title form"
        "image form ";
    }
`}
`;

export const StyledInlineImageContentContainer = styled.div<any>`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 0 13px;
  transform: translateY(59px);

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 0;
    transform: translateY(0);
  }
`;

export const StyledContentTitle = styled.div<any>`
  height: 20%;
  grid-area: title;
  h2 {
    @media (min-width: ${Breakpoints.b1280}px) {
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }
`;

export const StyledContentImage = styled.div<any>`
  display: initial;
  z-index: 1;
  grid-area: image;
  margin-top: -18%;
  padding: 0px 50px;
  @media (min-width: ${Breakpoints.b960}px) {
    margin-top: -15%;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 0;
    margin-top: 0;
    img {
      transform: scale(1.6);
    }
    .image-disclaimer-container {
      padding-top: 10%;
    }
  }
  @media (min-width: ${Breakpoints.b1600}px) {
    img {
      transform: scale(1.5);
    }
  }
  @media (min-width: ${Breakpoints.b2560}px) {
    img {
      transform: scale(1.4);
    }
  }
`;

export const StyledModalContainer = styled.div`
  position: fixed;
  z-index: 2000;

  .internal-modal-layer-wrapper {
    padding-top: 70px;

    @media (min-width: ${Breakpoints.b960 + 1}px) and (max-width: ${Breakpoints.b1280}px) {
      padding-top: 130px;
    }
  }

  .close-wrapper {
    top: 130px;

    @media (min-width: ${Breakpoints.b960 + 1}px) and (max-width: ${Breakpoints.b1280}px) {
      right: 21px;
    }

    @media (max-width: ${Breakpoints.b960}px) {
      right: 5px;
    }
  }
`;

export const StyledWidthContainer: any = styled.div`
  ${({ background, CSS }) => `
    width: 100%;
    background: ${background ? background : '#d7e158'};
    display: flex;
    align-items: center;
    justify-content: center;
    ${CSS ? CSS : ''}
  `}
`;
