import { styled, Breakpoints } from '@vw-marketing/us-components';

export const HeaderWrapper = styled.div`
  width: 80%;
  padding-bottom: 18px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 60%;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 45%;
  }
`;

export const TextWrapper = styled.div`
  width: 95%;
  padding-bottom: 66px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 57%;
    padding-bottom: 52px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 42%;
  }
`;

export const FailedTextWrapper = styled.div`
  width: 60%;
  @media (min-width: ${Breakpoints.b960}px) {
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 70%;
    padding-bottom: 18px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 50%;
  }
`;
