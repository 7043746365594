import { Breakpoints, styled } from "@vw-marketing/us-components";


export const StyledModalContainer = styled.div`
  position: fixed;
  z-index: 2000;

  .internal-modal-layer-wrapper {
    padding-top: 70px;

    @media (min-width: ${Breakpoints.b960 + 1}px) and (max-width: ${Breakpoints.b1280}px) {
      padding-top: 130px;
    }
  }

  .close-wrapper {
    top: 130px;

    @media (min-width: ${Breakpoints.b960 + 1}px) and (max-width: ${Breakpoints.b1280}px) {
      right: 21px;
    }

    @media (max-width: ${Breakpoints.b960}px) {
      right: 5px;
    }
  }
`;

export const StyledProcessViewContainer: any = styled.div`
  .Processing-class {
    position: fixed;
    z-index: 2000;
  }
`;
