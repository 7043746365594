import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledAppContainerProps {
  featureAppSize: string;
  theme: any;
}
export const StyledAppContainer = styled.div<StyledAppContainerProps>`
  width: 100%;
  ${({ featureAppSize, theme }: StyledAppContainerProps) => {
    return `
    @media (min-width: ${Breakpoints.b560}px) {

      padding:${
        featureAppSize && theme.size[featureAppSize]
          ? theme.size[featureAppSize]
          : '0px'
      };
      padding-top: 0px;
      padding-bottom: 0px;
      
    }
  `;
  }}
`;
