import React from 'react';
import _ from 'lodash';
import {
  TextCustom,
  ButtonCustom,
  TextAppearance,
  TextColor,
  ButtonAppearance,
  DisableFocusContainer,
  Richtext,
} from '@vw-marketing/us-components';
import {
  StyledBannerContainer,
  StyledBannerTitle,
  StyledBannerDescription,
  StyledBannerCTA,
  StyledBannerWrapper,
} from './styled';
import { useTrackingManager } from '../../services/use-tracking-manager';
import { onHandleBasicFormButtonLink } from '../../utils/tracking-helpers';
import { useModelData } from '../../services/use-model-data';
import { useFeatureAppMainConfig } from '../../services/use-feature-app-main-config';
import { descriptionOverrides, titleOverrides } from './helpers';
interface BannerProps {
  showModal?: () => void;
  isInline?: boolean;
}
const Banner: React.FC<BannerProps> = props => {
  const { showModal, isInline } = props;
  const { formConfiguration } = useFeatureAppMainConfig();
  const trackingManager = useTrackingManager();
  const modelData = useModelData();

  const onHandleClickEvent = () => {
    onHandleBasicFormButtonLink(
      trackingManager,
      formConfiguration,
      modelData,
      formConfiguration?.formName,
    );
    showModal && showModal();
  };

  return (
    <StyledBannerContainer
      background={formConfiguration?.background}
      className="banner_main-container"
    >
      <StyledBannerWrapper>
        <StyledBannerTitle>
          <Richtext
            overrides={titleOverrides}
            markdown={formConfiguration?.title || '$[formConfiguration.title]'}
          />
        </StyledBannerTitle>
        <StyledBannerDescription>
          <Richtext
            overrides={descriptionOverrides}
            markdown={
              formConfiguration?.description ||
              '$[formConfiguration.description]'
            }
          />
        </StyledBannerDescription>
        {!isInline && (
          <StyledBannerCTA>
            <DisableFocusContainer>
              <ButtonCustom
                onClick={onHandleClickEvent}
                appearance={ButtonAppearance.Secondary}
              >
                <TextCustom
                  appearance={TextAppearance.label150}
                  style={{ color: TextColor.inherit }}
                >
                  {formConfiguration?.CTAlabel ||
                    '$[formConfiguration.CTAlabel]'}
                </TextCustom>
              </ButtonCustom>
            </DisableFocusContainer>
          </StyledBannerCTA>
        )}
      </StyledBannerWrapper>
    </StyledBannerContainer>
  );
};

export default Banner;
