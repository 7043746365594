import { styled } from '@vw-marketing/us-components';

export const StyledProcessViewContainer: any = styled.div``;

interface ModalFormWrapperProps {
  withTopPadding: boolean;
}
export const ModalFormWrapper: any = styled.div<ModalFormWrapperProps>``;
export const StyledModalFormWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const ModalFormContainer: any = styled.div``;

interface StyledSuccessViewProps {
  showButton?: boolean;
}
export const StyledSuccessView: any = styled.div<StyledSuccessViewProps>`
  ${({ showButton }: StyledSuccessViewProps) =>
    showButton &&
    `button {
           display: none;
         }`}
`;
