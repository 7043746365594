import { styled, Breakpoints } from '@vw-marketing/us-components';

export const RadioGroupWrapper: any = styled.div`
  color: #001e50;
  display: block;
`;

export const RadioGroupLabelWrapper: any = styled.div`
  padding-bottom: 22px;
`;

// export const StyledSubmitButton: any = styled.div`
//   padding-top: 11px;
//   @media (min-width: ${Breakpoints.b960}px) {
//     padding-top: 21px;
//   }
// `;

export const StyledFormTitle: any = styled.div`
  padding-left: 30px;
  margin-bottom: 35px;
  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 50px;
  }
`;

export const StyledLegalDisclaimer: any = styled.div`
  padding-left: 30px;
  margin-right: 30px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding-right: 35%;
  }
`;

export const StyledSelectGroup: any = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  & > :first-child {
    padding-bottom: 48px;
  }
  @media (min-width: ${Breakpoints.b960}px) {
    flex-flow: unset;
    & > :first-child {
      padding-bottom: 0;
    }
  }
`;

export const StyledMasterWrapper: any = styled.div`
  margin-right: 30px;
  width: 100%;
  position: relative;
  &:focus {
    outline: none;
  }
`;

export const StyledDisableWrapper: any = styled.div`
  position: absolute;
  background: white;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  cursor: not-allowed;
`;

export const StyledSubmitButton: any = styled.div`
  ${({ theme }) => `
  padding-left: 30px;
  padding-top: 35px;
  width: ${theme.size.grid010};

  @media (min-width: ${Breakpoints.b960}px) {
    padding-top: 35px;
    width: ${theme.size.grid004};
  }
  &:focus-visible {
    outline: none;
  }
  `}
`;

export const StyledForm: any = styled.form`
  margin-bottom: 20px;
  z-index: 1;
`;

interface StyledFormsFieldsContainerProps {
  readonly structure: string;
  readonly columns: number;
}

export const StyledFormsFieldsContainer: any = styled.div<
  StyledFormsFieldsContainerProps
>`
  ${({ structure, columns }: StyledFormsFieldsContainerProps) => `
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 
    ${structure};
`}
`;

export const StyledModelWrapper: any = styled.div`
  grid-area: model;
  margin-bottom: 35px;
  margin-right: 30px;
  margin-left: 30px;
  .dropdown-group {
    .master-dropdown {
      margin-right: 120px;
    }
  }
  &:focus-visible {
    outline: none;
  }
`;
export const StyledFirstNameWrapper: any = styled.div`
  grid-area: firstName;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledLastNameWrapper: any = styled.div`
  grid-area: lastName;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledEmailWrapper: any = styled.div`
  grid-area: email;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledPhoneWrapper: any = styled.div`
  grid-area: phone;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledZipWrapper: any = styled.div`
  grid-area: zip;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledCommentsWrapper: any = styled.div`
  grid-area: comments;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;
export const StyledContactMethodWrapper: any = styled.div`
  grid-area: contactMethod;
  margin-bottom: 51px;
  margin-right: 30px;
  margin-left: 30px;
  &:focus-visible {
    outline: none;
  }
`;

export const StyledSlaveWrapper: any = styled.div`
  width: 100%;
  &:focus {
    outline: none;
  }
`;
