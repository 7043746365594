import {
  TextCustomProps,
  TextAppearance,
  TextColor,
  TextSize,
  TextLineHeight,
  TextCustom,
  ChevronDown,
  Text,
} from '@vw-marketing/us-components';
import React, { useState, ReactElement, useRef, useEffect } from 'react';
import {
  StyledDropdown,
  DropdownInput,
  StyledLabel,
  StyledLabelText,
  StyledIcon,
  StyledErrorText,
  StyledDropdownBoxWrapper,
  StyledDropdownBox,
  StyledDropdownBoxOption,
} from './styled';

export type dropdownOptionType = { value?: string; label?: string };

export interface DropdownCustomProps {
  readonly label?: string;
  readonly placeholder?: string;
  readonly selectedLabel?: string;
  readonly appearance?: string;
  readonly disabled?: boolean;
  readonly value?: dropdownOptionType;
  readonly errorText?: string;
  readonly onChange: (value: dropdownOptionType, touch?: boolean) => void;
  readonly onTouch?: () => void;
  readonly name?: string;
  readonly inputTextAppearance?: TextCustomProps;
  children: ReactElement[];
}

export const DropdownCustom: React.FC<DropdownCustomProps> = props => {
  const {
    label,
    placeholder,
    selectedLabel = '',
    errorText,
    appearance,
    onChange,
    disabled,
    onTouch,
    value,
    inputTextAppearance,
    children,
  } = props;
  const [showOption, setShowOption] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [labelValue, setLabelValue] = useState<
    { value?: string; label?: string } | undefined
  >(undefined);

  /** Change dropdown status */
  const toggleShowOption = () => {
    setShowOption(!showOption);
    onTouch && showOption && onTouch();
  };

  const errorShouldBeVisible = Boolean(appearance === 'error' && errorText);

  const textInputStyle = inputTextAppearance
    ? inputTextAppearance
    : {
        appearance: TextAppearance.headline200,
        style: {
          color: TextColor.primary,
          fontSize: TextSize.TextSize14,
          lineHeight: TextLineHeight.TextLineHeight24,
        },
      };

  /**
   * Handle dropdown change event
   * @param value
   */
  const handleDropdownChange = (
    value?: string,
    label?: string,
    touch?: boolean,
  ) => {
    const selectedOption: dropdownOptionType = { value, label };
    setShowOption(false);
    setLabelValue(selectedOption);
    value && onChange(selectedOption, touch);
  };

  /**
   * Hide dropdown option box when user is interacting with another component
   * @param event
   */
  const handleMouseDown = (event: any) => {
    !dropdownRef?.current?.contains(event.target) && setShowOption(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleMouseDown, false);
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousedown', handleMouseDown, false);
      }
    };
  }, []);

  useEffect(() => {
    if (labelValue?.value !== value?.value) {
      handleDropdownChange(value?.value, value?.label, false);
    }
  }, [value]);

  return (
    <StyledDropdown
      ref={dropdownRef}
      appearance={appearance}
      disabled={disabled}
    >
      <DropdownInput>
        {label && (
          <Text appearance={TextAppearance.label100} color={TextColor.inherit}>
            {label}
          </Text>
        )}
        <StyledLabel
          onClick={!disabled && toggleShowOption}
          disabled={disabled}
        >
          <StyledLabelText>
            <TextCustom {...textInputStyle}>
              {labelValue?.label
                ? selectedLabel + labelValue.label
                : placeholder}
            </TextCustom>
          </StyledLabelText>
          <StyledIcon className={showOption && 'active'}>
            <div className={`animation ${showOption && 'active'}`}>
              <ChevronDown />
            </div>
          </StyledIcon>
        </StyledLabel>
      </DropdownInput>
      {!showOption && errorShouldBeVisible && (
        <StyledErrorText role="alert">
          {errorShouldBeVisible && errorText}
        </StyledErrorText>
      )}
      {showOption && (
        <StyledDropdownBoxWrapper>
          <StyledDropdownBox>
            {children.map((child: ReactElement) => (
              <StyledDropdownBoxOption
                key={child.key || 'child-key'}
                onClick={handleDropdownChange.bind(
                  null,
                  child.props.value,
                  child.props.label,
                  true,
                )}
              >
                {child}
              </StyledDropdownBoxOption>
            ))}
          </StyledDropdownBox>
        </StyledDropdownBoxWrapper>
      )}
    </StyledDropdown>
  );
};

export * from './dropdown-option';
