import { styled, Breakpoints } from '@vw-marketing/us-components';

interface StyledBannerContainerProps {
  background: string;
}
export const StyledBannerContainer: any = styled.div<
  StyledBannerContainerProps
>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 44px 0;
  flex-flow: column;
  background: ${({ background }: StyledBannerContainerProps) =>
    background ||
    `linear-gradient(
           to bottom,
           #00437a -50%,
           #001e50 50%
         )`};

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 68px 0;
  }
`;

export const StyledBannerWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  flex-flow: column;
`;

export const StyledBannerTitle: any = styled.div`
  color: #ffffff;
  width: 100%;
  padding: 0 31px;
  @media (min-width: ${Breakpoints.b960}px) {
    width: auto;
  }
`;
export const StyledBannerDescription: any = styled.div`
  color: #ffffff;
  width: 100%;
  padding: 20px 31px 28px 31px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 75%;
  }
  @media (min-width: ${Breakpoints.b960}px) {
    width: 454px;
    padding: 24px 0 52px 0;
  }
`;

export const StyledBannerCTA: any = styled.div`
  width: auto;
  button {
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0 24px;
  }
  button:hover {
    background: rgb(76, 199, 244);
    border-color: rgb(76, 199, 244);
    color: rgb(0, 30, 80);
  }
  button:focus {
    background: rgb(76, 199, 244);
    border-color: rgb(76, 199, 244);
    color: rgb(0, 30, 80);
  }
`;
