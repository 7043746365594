import React from 'react';
import { Richtext } from '@vw-marketing/us-components';
import { FormState, ModelImage } from '../../typings/general';
import { DynamicForm } from '../dynamic-form';
import _ from 'lodash';
import { useModelStructure } from '../../services/use-model-structure';
import {
  ModalFormWrapper,
  StyledModalFormWrapper,
  StyledSuccessView,
} from './styled';
import {
  SuccessConfiguration,
  SuccessView,
} from '../form-state-views/success-view';
import { FailedView } from '../form-state-views/failed-view';
import { useModelData } from '../../services/use-model-data';
import { getSuccessFormContent, titleOverridesTags } from './helpers';
import {
  FieldsConfiguration,
  FormConfiguration,
} from '../../typings/configuration';
import { ConfigFieldsModel } from '../../config/formFields';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';

interface FormProps {
  formState: FormState;
  setFormState: (state: FormState) => void;
  modelImage: ModelImage;
  onCloseHandle?: () => void;
  isFormInline?: boolean;
  withPadding?: boolean;
  fieldsConfiguration: FieldsConfiguration;
  formConfiguration: FormConfiguration;
  fieldsFormat: ConfigFieldsModel;
}
const Form: React.FC<FormProps> = props => {
  let {
    formState,
    setFormState,
    modelImage,
    isFormInline,
    onCloseHandle,
    withPadding,
    fieldsConfiguration,
    formConfiguration,
    fieldsFormat,
  } = props;
  const modelStructure = useModelStructure();
  const { faHostServerUrl = '' } = useFeatureAppConfig();

  // TRACKING -----------------------------------------------------------
  // const { touched, errors, values }: FormikContextType<any> = useFormikContext();
  // const [errorHistory, setErrorsHistory] = useState<any>({});
  // const trackingManager = useTrackingManager();

  // // This useEffect is used for tracking fields errors when they appear on screen.
  // useEffect(() => {
  //   if (formConfiguration.disableTracking) return;
  //   if (!_.isEmpty(errors) && !_.isEmpty(touched)) {
  //     const actualErrors = _.keys(errors).filter((key: string) => (touched[key]));
  //     const formFields = _.keys(values).filter((key: string) => (values[key]))
  //     let newErrorHistory: any = errorHistory;
  //     actualErrors.map((errorField: string) => {
  //       if (!errorHistory[errorField]) {
  //         onHandleFormSubmitTracking(
  //           'error',
  //           formConfiguration.formName,
  //           trackingManager,
  //           formConfiguration,
  //           undefined,
  //           undefined,
  //           { errorField: errorField, errorFields: actualErrors, formFields },
  //           { message: errors[errorField]?.toString() }
  //         );
  //         newErrorHistory = { ...newErrorHistory, [errorField]: true };
  //       }
  //     })
  //     let newErrorKeys = _.keys(errorHistory);
  //     newErrorKeys.map((errorKey: string) => {
  //       const isFieldOnErrors = actualErrors.find((compareField) => compareField === errorKey);
  //       if (!isFieldOnErrors) {
  //         newErrorHistory = { ...newErrorHistory, [errorKey]: false }
  //       }
  //     });
  //     setErrorsHistory(newErrorHistory);
  //   }
  //   _.isEmpty(errors) && setErrorsHistory({});
  // }, [touched, errors, values]);
  // END TRACKING --------------------------------------------------------

  const modelData = useModelData();
  const onCloseHandleForm = () => {
    onCloseHandle && onCloseHandle();
  };
  if (modelData?.name && typeof fieldsConfiguration.modelName !== 'undefined') {
    fieldsConfiguration = {
      ...fieldsConfiguration,
      modelName: modelData?.name || fieldsConfiguration?.modelName,
    };
  }
  const successViewContentConfig: SuccessConfiguration = getSuccessFormContent(
    modelImage,
    faHostServerUrl,
    formConfiguration?.successMessageContent,
  );
  return (
    <ModalFormWrapper withTopPadding={withPadding}>
      <StyledModalFormWrapper>
        {formState === 'filling' || formState === 'submitting' ? (
          <DynamicForm
            requiredfields={fieldsConfiguration}
            formStructure={formConfiguration.formStructure}
            modelsMap={modelStructure}
            columns={formConfiguration.structureColumns}
            submitLabel={formConfiguration.submitCTA}
            legalDisclaimer={formConfiguration.legalDisclaimer}
            formName={formConfiguration.formName}
            carlineName={modelData?.name}
            hideDropdown={formConfiguration.hideModelDropdown}
            fieldsConfig={fieldsFormat}
          >
            <Richtext
              markdown={
                formConfiguration.modalTitle ||
                formConfiguration.formName ||
                '${formConfiguration.formName}'
              }
              overrides={titleOverridesTags}
              dangerouslyParseRawHTML
            />
          </DynamicForm>
        ) : formState === 'success' ? (
          <StyledSuccessView showButton={isFormInline}>
            <SuccessView
              okHandler={onCloseHandleForm}
              inline={isFormInline}
              contentConfiguration={successViewContentConfig}
            />
          </StyledSuccessView>
        ) : (
          <FailedView
            formName={
              formConfiguration.formName || '${formConfiguration.formName}'
            }
            setFormStateHandler={() => setFormState('filling')}
            inline={isFormInline}
            contentConfig={formConfiguration?.failedMessageContent}
          />
        )}
      </StyledModalFormWrapper>
    </ModalFormWrapper>
  );
};

export default Form;
