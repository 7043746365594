import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { dropdownOptionType, ElementProps } from '@vw-marketing/us-components';
import { FormikProps } from 'formik';

import { DropdownCustom } from './dropdown-custom';
import {
  StyledSelectGroup,
  StyledMasterWrapper,
  StyledDisableWrapper,
  StyledSlaveWrapper,
} from './dynamic-form/styled';
interface SelectGroupProps {
  masterSelect: ElementProps;
  slaveSelect: ElementProps;
  dropdownOption: { [key: string]: string[] };
  formikProps: FormikProps<any>;
  disableMaster?: boolean;
  selectedMaster?: dropdownOptionType;
}

/**
 * Compoused input for 2 dropdown, the second dropdown (slave)
 * depends of first dropdown (master) data
 * @param props
 */
export const SelectGroup: React.FC<SelectGroupProps> = props => {
  const {
    dropdownOption,
    masterSelect,
    slaveSelect,
    formikProps,
    disableMaster,
    selectedMaster,
  } = props;
  const {
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    setValues,
  } = formikProps;
  const [slaveSelectOptions, setSlaveSelectOptions] = useState<string[]>([]);
  const masterSelectOptions: string[] = Object.keys(dropdownOption);

  /**
   * Change master dropdown and slave according to master options
   * @param value
   */
  const onHandleMasterChange = (
    option: dropdownOptionType,
    touch?: boolean,
  ) => {
    const value = option.value || '';
    if (disableMaster) return;
    setValues({
      ...values,
      [masterSelect.name]: value,
      [slaveSelect.name]: dropdownOption[value][0],
    });
    setSlaveSelectOptions(dropdownOption[value]);
    // touch && setFieldTouched(masterSelect.name, true, false);
  };

  /**
   * Change Slave dropdown
   * @param optionValue
   */
  const onHandleSlaveChange = (
    optionValue: dropdownOptionType,
    touch?: boolean,
  ) => {
    let value = optionValue.value;
    if (!optionValue) value = '';
    setFieldValue(slaveSelect.name, value);
    touch && setFieldTouched(slaveSelect.name, true, false);
  };

  useEffect(() => {
    selectedMaster &&
      //@ts-ignore
      setFieldValue(masterSelect.name, selectedMaster.value, true) &&
      //@ts-ignore
      setFieldTouched(masterSelect.name, true, false) &&
      setSlaveSelectOptions(dropdownOption[selectedMaster.value || '']);
  }, []);
  /**
   * Get master options to render
   */
  const getMasterOptions = (): JSX.Element[] => {
    if (masterSelectOptions.length) {
      return masterSelectOptions.map((masterOption: string) =>
        React.createElement(
          'div',
          {
            value: masterOption,
            label: masterOption,
          },
          masterOption,
        ),
      );
    }
    return [<></>];
  };

  /**
   * Get slave options to render
   */
  const getSlaveOptions = (): JSX.Element[] => {
    {
      if (slaveSelectOptions && slaveSelectOptions.length) {
        return slaveSelectOptions.map((slaveOption: string) =>
          React.createElement(
            'div',
            {
              value: slaveOption,
              label: slaveOption,
            },
            slaveOption,
          ),
        );
      }
      return [<></>];
    }
  };

  return (
    <StyledSelectGroup className="dropdown-group">
      {
        <StyledMasterWrapper
          className="master-dropdown"
          disable={disableMaster}
          tabIndex={0}
        >
          {disableMaster && <StyledDisableWrapper />}
          <DropdownCustom
            name={masterSelect.name}
            label={masterSelect.label}
            value={{
              value: values[masterSelect.name],
              label: values[masterSelect.name],
            }}
            placeholder={masterSelect.placeholder}
            onChange={onHandleMasterChange}
            appearance={
              errors[masterSelect.name] && touched[masterSelect.name]
                ? 'error'
                : 'default'
            }
            errorText={errors[masterSelect.name] as string}
            onTouch={setFieldTouched.bind(null, masterSelect.name, true, true)}
            disabled={disableMaster}
          >
            {getMasterOptions()}
          </DropdownCustom>
        </StyledMasterWrapper>
      }
      <StyledSlaveWrapper className="slave-dropdown" tabIndex={0}>
        <DropdownCustom
          name={slaveSelect.name}
          label={slaveSelect.label}
          onChange={onHandleSlaveChange}
          value={{
            value: values[slaveSelect.name],
            label: values[slaveSelect.name],
          }}
          placeholder={slaveSelect.placeholder}
          appearance={
            errors[slaveSelect.name] && touched[slaveSelect.name]
              ? 'error'
              : 'default'
          }
          onTouch={setFieldTouched.bind(null, slaveSelect.name, true, true)}
          errorText={errors[slaveSelect.name] as string}
          disabled={
            _.isEmpty(values[slaveSelect.name]) &&
            _.isEmpty(values[masterSelect.name])
          }
        >
          {getSlaveOptions()}
        </DropdownCustom>
      </StyledSlaveWrapper>
    </StyledSelectGroup>
  );
};
