import {
  createTagOverride,
  TextCustom,
  TextAppearance,
  TextWeight,
  TextTag,
  CustomImageProps,
  TextSize,
  TextLineHeight,
  TextAlignment,
  Breakpoints,
} from '@vw-marketing/us-components';
import _ from 'lodash';
import { ImageDisclaimerProps } from '../../components/image-disclaimer';
import { InlineImageModeImageConfig } from '../../typings/inline-image-form';
import { AppConstants } from '../../utils/app-constants';

/**
 * Getting form request data from metadata and requested fields
 */
// export const getFormRequestData = (
//   fields?: FormFields[],
//   metaData?: string,
// ) => {
//   let fieldsInputs = {};
//   try {
//     let mainObject = metaData && JSON.parse(metaData);
//     if (!fields || fields.length === 0) throw new Error('No fields configured');
//     fieldsInputs = _.keyBy(fields, 'formType');
//     fieldsInputs = _.mapValues(fieldsInputs, () => '');
//     fieldsInputs = { ...mainObject, ...fieldsInputs };
//     return;
//   } catch (error) {
//     console.log('ERROR configuring form data:', (error as any).message);
//   } finally {
//     return fieldsInputs && !_.isEmpty(fieldsInputs) ? fieldsInputs : undefined;
//   }
// };
// /**
//  * Merging form configuration sources
//  * @param defaultForm
//  * @param formConfig
//  * @param featureContent
//  * @returns
//  */
// export const getFormConfiguration = (
//   defaultForm: FormConfiguration,
//   formConfig?: FormConfiguration,
//   featureContent?: FeatureAppContent,
// ): FormConfiguration => {
//   const { successMessageContent, failedMessageContent, content } =
//     featureContent || {};
//   let mergedConfig = {
//     ...defaultForm,
//     ...formConfig,
//   };
//   mergedConfig = featureContent?.style?.length
//     ? { ...mergedConfig, ...featureContent?.style[0] }
//     : mergedConfig;
//   let successMessage = successMessageContent?.length
//     ? {
//         ...successMessageContent[0],
//         image: {
//           src: successMessageContent[0].imageSrc || '',
//           alt: successMessageContent[0].imageAlt || '',
//           srcPlaceholder: AppConstants.PlaceHolder480x216,
//           srcErrorImage: successMessageContent[0].errorImageSrc,
//         },
//       }
//     : undefined;
//   let failedMessage = failedMessageContent?.length
//     ? failedMessageContent[0]
//     : undefined;
//   mergedConfig = successMessage
//     ? { ...mergedConfig, successMessageContent: successMessage }
//     : mergedConfig;
//   mergedConfig = failedMessage
//     ? { ...mergedConfig, failedMessageContent: failedMessage }
//     : mergedConfig;
//   mergedConfig = content?.length
//     ? { ...mergedConfig, ...content[0] }
//     : mergedConfig;
//   return mergedConfig;
// };

/**
 * Getting default image object
 * @param image
 * @returns CustomImageProps
 */
export const getFormImageConfiguration = (
  faHostServerUrl: string,
  image?: InlineImageModeImageConfig[],
): CustomImageProps & ImageDisclaimerProps => {
  let defaultImage: CustomImageProps & ImageDisclaimerProps = {
    src: faHostServerUrl + AppConstants.Ngw6Logo480x216,
    alt: 'vw image default',
    srcErrorImage: faHostServerUrl + AppConstants.Ngw6Logo480x216,
    srcPlaceholder: AppConstants.PlaceHolder480x216,
  };
  if (image?.length && !_.isEmpty(image[0])) {
    defaultImage = image[0].src
      ? { ...defaultImage, src: image[0].src }
      : defaultImage;
    defaultImage = image[0].alt
      ? { ...defaultImage, alt: image[0].alt }
      : defaultImage;
    defaultImage = image[0].errorImageSrc
      ? { ...defaultImage, srcErrorImage: image[0].errorImageSrc }
      : defaultImage;
    defaultImage = image[0].disclaimer
      ? { ...defaultImage, description: image[0].disclaimer }
      : defaultImage;
  }
  return defaultImage;
};

/**
 * Text styling definitions
 */
const titleRegularStyle = {
  [Breakpoints.default]: {
    fontSize: TextSize.TextSize28,
    lineHeight: TextLineHeight.TextLineHeight32,
    fontWeight: TextWeight.light,
    textAlign: TextAlignment.left,
  },
  [Breakpoints.b1280]: {
    fontSize: TextSize.TextSize36,
    lineHeight: TextLineHeight.TextLineHeight40,
    fontWeight: TextWeight.light,
    textAlign: TextAlignment.left,
  },
};

const titleBiggestStyle = {
  [Breakpoints.default]: {
    fontSize: TextSize.TextSize56,
    lineHeight: TextLineHeight.TextLineHeight60,
    fontWeight: TextWeight.bold,
    textAlign: TextAlignment.left,
  },
  [Breakpoints.b1280]: {
    fontSize: TextSize.TextSize60,
    lineHeight: TextLineHeight.TextLineHeight40,
    fontWeight: TextWeight.bold,
    textAlign: TextAlignment.left,
  },
};

const titleTextAppearance = TextAppearance.headline300;

export const titleOverridesTags: any = {
  span: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleRegularStyle },
    tag: TextTag.span,
  }),
  div: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleRegularStyle },
    tag: TextTag.div,
  }),
  p: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleRegularStyle },
    tag: TextTag.p,
  }),
  strong: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleRegularStyle, fontWeight: TextWeight.bold },
    tag: TextTag.strong,
  }),
  h2: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleBiggestStyle },
    tag: TextTag.h2,
  }),
  h3: createTagOverride(TextCustom, {
    appearance: titleTextAppearance,
    style: { ...titleRegularStyle },
    tag: TextTag.h3,
  }),
};

/**
 * Testing CSS style from string
 * @param style
 * @returns
 */
export const isValidCSS = (style?: string): boolean => {
  const cssRegExp = new RegExp(
    /((?:^\s*)([\w#.@*,:\-.:>,*\s]+)\s*{(?:[\s]*)((?:[A-Za-z\- \s]+[:]\s*['"0-9\w .,\/()\-!%]+;?)*)*\s*}(?:\s*))/gim,
  );
  if (!style) return false;
  return cssRegExp.test(style);
};
