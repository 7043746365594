import {
  createTagOverride,
  TextAlignment,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
} from '@vw-marketing/us-components';
import { AppConstants } from '../../../utils/app-constants';

export const getDefaultContentConfig = (faHostServerUrl: string) => ({
  headerLabel: 'Thank you for contacting us!',
  description:
    '<p>We have received your message. <br /> <br /> Our team will contact you as soon as possible to give you the information you need.</p>',
  buttonCtaLabel: 'Okay',
  image: {
    src: '',
    srcPlaceholder: AppConstants.PlaceHolder480x216,
    srcErrorImage: faHostServerUrl + AppConstants.Ngw6Logo480x216,
    alt: '',
  },
});

const descriptionTextStyle = createTagOverride(TextCustom, {
  appearance: TextAppearance.headline200,
  style: {
    textAlign: TextAlignment.center,
    fontSize: TextSize.TextSize20,
    lineHeight: TextLineHeight.TextLineHeight24,
  },
  tag: TextTag.p,
});

export const descriptionSuccessTextStyle: any = {
  div: descriptionTextStyle,
  p: descriptionTextStyle,
  span: descriptionTextStyle,
};
