import {
  DisableFocusContainer,
  ButtonCustom,
  ButtonAppearance,
  TextCustom,
  TextAppearance,
  TextColor,
  TextSize,
} from '@vw-marketing/us-components';
import React from 'react';

interface ModalCTAProps {
  label: string;
  onClickHandle: () => void;
}

const ModalCTA: React.FC<ModalCTAProps> = props => {
  const { label, onClickHandle } = props;
  return (
    <div className="modal-cta-button">
      <DisableFocusContainer>
        <ButtonCustom
          onClick={onClickHandle}
          appearance={ButtonAppearance.Primary}
          stretchContent
        >
          <TextCustom
            appearance={TextAppearance.label150}
            style={{ color: TextColor.inherit }}
          >
            {label || '$[formConfiguration.CTAlabel]'}
          </TextCustom>
        </ButtonCustom>
      </DisableFocusContainer>
    </div>
  );
};

export default ModalCTA;
